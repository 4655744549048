<template>
	<div class="footers">
		<div class="logo-bar">
			<div class="row">
				<div class="columns column12">
					<h4>{{ $t('hotelsInOurCollection') }}</h4>
				</div>
				<div class="columns column12 logo-column">
					<div>
						<a href="https://www.casajulia.nl/" target="_blank">
							<img
								src="~/assets/images/finehotels/logo-casa-julia.svg"
								class="footer-logo"
								style="width: 100%; max-width: 129px"
								loading="lazy"
								alt="Casa Julia"
							/>
						</a>
					</div>
					<div>
						<a href="https://www.stadsvillamout.nl/" target="_blank">
							<img
								src="~/assets/images/finehotels/logo-stadsvilla-mout.svg"
								class="footer-logo"
								style="width: 100%; max-width: 167px"
								loading="lazy"
								alt="Stadsvilla Mout"
							/>
						</a>
					</div>
					<div>
						<a href="https://www.mozaic.nl/" target="_blank">
							<img
								src="~/assets/images/finehotels/logo-stadsvilla-mozaic.svg"
								class="footer-logo"
								style="width: 100%; max-width: 167px"
								loading="lazy"
								alt="Stadsvilla Mozaic"
							/>
						</a>
					</div>
					<div>
						<a href="https://www.bergsebossen.nl/" target="_blank">
							<img
								src="~/assets/images/finehotels/logo-buitenplaats-bergse-bossen.svg"
								class="footer-logo"
								style="width: 100%; max-width: 263px"
								loading="lazy"
								alt="Buitenplaats Bergse Bossen"
							/>
						</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<div class="border" />
				</div>
			</div>
		</div>
		<footer class="main-footer">
			<div class="row">
				<div class="columns column4">
					<img
						src="~/assets/images/finehotels/logo-fine-hotels-suites.svg"
						class="footer-logo"
						style="width: 100%; max-width: 120px"
						alt="Fine Hotels & Suites"
					/>
					<social-media :socials="socials" />
				</div>
				<div class="columns column4">
					<h4>{{ $t('footerNavigate') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
						<a v-if="defaults[locale].cookieConsent.published" href="#" @click.prevent="openConsent"
							>{{ $t('consentOpen') }}
						</a>
					</p>
				</div>
				<div class="columns column4">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in bottomfooter[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
						<a v-if="defaults[locale].cookieConsent.published" href="#" @click.prevent="openConsent"
							>{{ $t('consentOpen') }}
						</a>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<div class="bottomfooter-row">
						<p class="align-center">
							<a
								href="https://www.becurious.com"
								target="_blank"
								rel="noopener noreferrer"
								title="Hotel website design by Becurious"
							>
								Hotel Website Design by Becurious</a
							>
						</p>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();
const { openConsent } = useCookieConsent();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: var(--footer-color);
	background: var(--footer-background-color);

	a,
	span {
		color: var(--footer-color);
		text-decoration: none;
	}

	h3,
	h4 {
		color: var(--footer-color);
		letter-spacing: 1px;
	}

	h4 {
		font-family: var(--body-font-family);
		font-size: var(--body-font-size);
		font-weight: 600;
	}
}

.logo-bar {
	margin: 75px 0 0;

	.columns {
		padding: 20px;
	}

	.logo-column {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: flex-start;

		h4 {
			width: 100%;
		}

		div {
			width: auto;
		}
	}

	a {
		margin: 20px 0 0;
		display: inline-block;
	}

	.border {
		margin: 30px 0 0;
	}
}

.main-footer {
	padding: 50px 0;

	p {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.socials {
		margin: 20px 0 0 10px;
	}
}

.bottomfooter-row {
	display: flex;
	align-items: center;
	width: 100%;
	text-align: center;

	.menu {
		margin-left: auto;
	}

	p {
		width: 100%;
	}
}

.author-footer {
	font-size: 12px;
	padding: 0;
	text-align: center;
	width: 100%;

	img {
		max-width: 20px;
		aspect-ratio: 2 / 3;
		margin: 0 1px 0 2px;
	}
}

.border {
	background: #333;
	width: 100%;
	height: 1px;
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 15px auto;
	}

	.logo-bar {
		margin: 50px auto;

		.column3 {
			width: calc(100% - 40px);
			margin: 0 auto 25px;
			padding: 0 20px 50px;
			border-right: 0;
			border-bottom: 1px solid #000;
			text-align: center;
		}
	}
}

@media (max-width: 580px) {
	footer {
		text-align: center;
	}

	.logo-bar {
		h4 {
			margin: 0;
		}

		.logo-column {
			flex-flow: column wrap;
		}

		.column9 {
			justify-content: space-between;

			a {
				width: 25%;

				&:nth-child(2) {
					img {
						max-width: 70px !important;
					}
				}
			}
		}
	}

	.main-footer {
		padding: 75px 0 50px;
	}

	.bottomfooter-row {
		flex-flow: column-reverse wrap;
		align-items: center;

		p,
		.menu {
			margin: 5px auto;
			text-align: center;
		}
	}
}

@media (max-width: 420px) {
	.logo-bar {
		.column9 {
			a {
				&:nth-child(2) {
					img {
						max-width: 60px !important;
					}
				}
			}
		}
	}
}
</style>
